import Image from 'next/image';
import styles from './expertise-card.module.scss';
import { ReactSVG } from 'react-svg';
import Link from 'next/link';

type ExpertiseCardProps = {
  title: string;
  url: string;
  iconSrc: string;
  iconAlt?: string;
  iconWidth?: number;
  iconHeight?: number;
}

export const ExpertiseCard = (
  {title, url, iconSrc, iconHeight = 60, iconWidth = 60, iconAlt}: ExpertiseCardProps
) => {
  return (
    <Link href={url}>
      <a rel='preload' className={styles.card}>
        <div className={styles.card__info}>
          <Image
            src={iconSrc}
            alt={iconAlt ?? `${title} expertise icon`}
            width={iconWidth}
            height={iconHeight}
            layout={"fixed"}
            objectFit={"contain"}
            loading='lazy'
          />
          <h3 className={styles.card__title}>{title}</h3>
        </div>
        <ReactSVG
          className={styles.card__svg}
          src={'/shared/arrow-right-up.svg'}
          alt={`Find out more about ${title}`}
          width={24}
          height={24}
        />
      </a>
    </Link>
  );
}
